<template>
  <v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pt-0">
      <v-btn-toggle v-model="tap" class="transparent" mandatory>
<!--        <v-btn class="ma-0" active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" :value="0" outlined style="width: 235px; height: 50px">정산 내역</v-btn>-->
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="1" outlined style="width: 352px; height: 50px">세부 결제 내역</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="2" outlined style="width: 352px; height: 50px">부가세 신고</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.smAndDown" class="pt-0 px-4">
      <v-btn-toggle v-model="tap" class="transparent" mandatory style="width: 100%">
<!--        <v-btn class="ma-0" active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" :value="0" outlined style="width: 33%; height: 50px">정산 내역</v-btn>-->
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="1" outlined style="width: 50%; height: 50px">세부 결제 내역</v-btn>
        <v-btn class="ma-0" active-class="rouge--text text--rouge v-btn--active" :value="2" outlined style="width: 50%; height: 50px">부가세 신고</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="content-page mt-2 pt-0">
      <Schedule :option="option" :schedules="schedules" v-if="tap === 0"></Schedule>
      <PaymentHistory :settlements="settlements" v-if="tap === 1"></PaymentHistory>
      <VAT :schedules="schedules" v-if="tap === 2"></VAT>
    </v-col>
  </v-col>
</template>

<script>
  import companyMixin from '@/components/shared/companyMixin'
  import userMixin from "@/components/shared/userMixin";

  export default {
    name: 'Settlement',
    mixins: [userMixin, companyMixin],
    components: {
      Schedule: () => import('@/components/company/settlement/Schedule'),
      PaymentHistory: () => import('@/components/company/settlement/PaymentHistory'),
      VAT: () => import('@/components/company/settlement/VAT'),
    },
    data() {
      return {
        tap: 1,
        option: {'banks': {}},
        settlements: [],
        schedules: []
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    async created() {
      let option = await this.axios.get('/company/option');
      this.option = option.data;

      // v-if="props.item && (new Date(props.item.paidAt).getMonth() === new Date().getMonth())

      let settlements = await this.axios.get('/company/user/settlement?userId=' + this.user.id);
      if (settlements.data.total > 0) {
        this.settlements = settlements.data.settlements;
        this.schedules = settlements.data.schedules;
        if (this.settlements.length > 0) {
          this.settlements.forEach((settlement) => {
            let company = this.companies.filter((company) => {
              return (company.id == settlement.cid);
            });
            settlement.companyName = company[0].name;
          });
        }
        this.settlements = this._.orderBy(this.settlements, ['paidAt'], ['desc']);
        this.schedules = this._.orderBy(this.schedules, ['date'], ['desc']);
        // this.settlements.push(...this.settlements);
        // this.settlements = this.settlements.slice();
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
